import {useUser} from "../hooks/useUser";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {CardPropsColorOverrides} from "@mui/joy/Card/CardProps";
import {StripeProduct} from "./StripeProduct";
import {StripePriceInfoResponse} from "../data/StripeResponses";


interface PremiumSubscriptionProps {
    name: string
    shortDescription: string
    priceInfo: StripePriceInfoResponse
    benefits: string[]
    cardColor?: OverridableStringUnion<ColorPaletteProp, CardPropsColorOverrides>
}

export const PremiumSubscription = (props: PremiumSubscriptionProps) => {
    const account = useUser({requireUser: false})

    const activeSubscription = account.user?.activeSubscription

    const hasActiveSubscription = activeSubscription != null &&
        activeSubscription.status !=="incomplete_expired" &&
        activeSubscription.status !== "canceled"

    return <StripeProduct
        name={props.name}
        priceInfo={props.priceInfo}
        benefits={props.benefits}
        cardColor={props.cardColor}
        buttonText={hasActiveSubscription ? "Manage Subscription" : "Subscribe"}
        shortDescription={props.shortDescription}
        canPurchase={!hasActiveSubscription}
        period="30 days"
        maxQuantity={1}
    >
    </StripeProduct>
}
import {AspectRatio, Card, CardContent, Typography} from "@mui/joy";
import {PropsWithChildren} from "react";

interface DetailCardProps  extends PropsWithChildren {
    title: string
    description: string
    image: string
}

export const DetailCard = (props: DetailCardProps) => {
    return <Card variant="plain" orientation="horizontal">
                <AspectRatio ratio="1" sx={{width: 250, display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }  }}>
                    <img
                        src={props.image}
                        style={{ filter: "brightness(80%)" }}
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
                <CardContent>
                    <Typography level="h3">
                        {props.title}
                    </Typography>
                    <Typography>
                        {props.description}
                    </Typography>
                    {props.children}
                </CardContent>
            </Card>
}
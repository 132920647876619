import {Box, List, ListItem} from "@mui/joy";
import {useNavigate} from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate()

    return <Box
        component="footer"
        sx={{
            marginTop: 'auto',
            width: '100%'
        }}>
        <List role="menubar" orientation="horizontal" sx={{ justifyContent: "center"}}>
            <ListItem>
                <a style={{color: "inherit", fontSize: "0.8rem"}}
                   href="/terms-of-service"
                   onClick={(e) => {
                       e.preventDefault()
                       navigate("/terms-of-service")
                   }}
                   rel="noreferrer">
                    Terms of Service
                </a>
            </ListItem>
            <ListItem>
                <a style={{color: "inherit", fontSize: "0.8rem"}}
                   href="/privacy-policy"
                   onClick={(e) => {
                       e.preventDefault()
                       navigate("/privacy-policy")
                   }}
                   rel="noreferrer">
                    Privacy Policy
                </a>
            </ListItem>
            <ListItem>
                <a style={{color: "inherit", fontSize: "0.8rem"}}
                   href="https://perpheads.com/direct-messages/add?to=Fredy,Bolli"
                   target="_blank"
                   rel="noreferrer">
                    Contact Us
                </a>
            </ListItem>
        </List>
    </Box>
}
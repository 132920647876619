import {IconButton, Tooltip, useColorScheme} from "@mui/joy";
import {DarkMode, LightMode} from "@mui/icons-material";

export const ModeToggle = () => {
    const {mode, setMode} = useColorScheme();

    return <Tooltip title="Switch Theme">
        <IconButton
            onClick={() => {
                setMode((mode === "light") ? "dark" : "light")
            }}
        >
            {(mode === "light") ? <DarkMode/> : <LightMode/>}
        </IconButton>
    </Tooltip>
}
import {Sheet} from "@mui/joy";
import ReactMarkdown from "react-markdown";
import {useEffect, useState} from "react";

interface LegalMarkdownProps {
    path: string
}

export const LegalMarkdown = (props: LegalMarkdownProps) => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch(props.path)
            .then((response) => response.text())
            .then((text) => setMarkdown(text))
            .catch((error) => console.error("Error loading terms file:", error));
    }, [props.path]);

    return <Sheet sx={{p: "1% 5% 5% 5%", marginTop: "5%", borderRadius: "10px"}}>
        <ReactMarkdown>{markdown}</ReactMarkdown>
    </Sheet>
}
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {CardPropsColorOverrides} from "@mui/joy/Card/CardProps";
import {StripeProduct} from "./StripeProduct";
import {StripePriceInfoResponse} from "../data/StripeResponses";

interface PremiumTokenProps {
    name: string
    shortDescription: string
    priceInfo: StripePriceInfoResponse
    benefits: string[]
    cardColor?: OverridableStringUnion<ColorPaletteProp, CardPropsColorOverrides>
}

export const PremiumToken = (props: PremiumTokenProps) => {
    return <StripeProduct
        name={props.name}
        priceInfo={props.priceInfo}
        benefits={props.benefits}
        cardColor={props.cardColor}
        buttonText="Purchase"
        shortDescription={props.shortDescription}
        canPurchase={true}
        maxQuantity={100}
    />
}
import {createContext, PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {ApiCallResponseData, makeApiCall} from "./CancellableApiCall";

export interface AccountResponse {
    communityId: string
    nick: string
    customerId: string | null
    addressId: string | null
    avatarLink: string | null
    mediumAvatarLink: string | null
    fullAvatarLink: string | null
    permissions: string[]
    termsOfServiceAccepted: boolean
    activeSubscription: SubscriptionResponse | null
}

interface SubscriptionResponse {
    subscriptionId: string
    customerId: string
    status: string
    createdAt: string
    currentPeriodStart: string
    currentPeriodEnd: string
    activeSubscription: AccountResponse
}

export const userHasPermissions = (user: AccountResponse, permissions: string | string[]): boolean => {
    if (Array.isArray(permissions)) {
        return permissions.every((permission) => user.permissions.includes(permission))
    } else {
        return user.permissions.includes(permissions)
    }
}

export interface UserContextProps {
    user: AccountResponse | null
    isLoading: boolean
    isError: boolean
    refresh: () => void,
    hasPermissions: (permissions: string | string[]) => boolean
}

export const UserContext = createContext<UserContextProps>({
    user: null,
    isLoading: false,
    isError: false,
    refresh: () => {
    },
    hasPermissions: (_: string | string[]) => false
})


export const UserProvider = ({children}: PropsWithChildren) => {
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [user, setUser] = useState<AccountResponse>()
    const loadingData = useRef({
        loading: false
    })

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall])

    const hasPermissions = (permissions: string | string[]) => {
        if (!user) return false
        return userHasPermissions(user, permissions)
    }

    const refresh = () => {
        if (loadingData.current.loading) {
            return
        }
        loadingData.current.loading = true
        setIsLoading(true)
        setApiCall(makeApiCall({
            url: "/api/user/-",
            onLoadedCallback: (account: AccountResponse) => {
                loadingData.current.loading = false
                setIsLoading(false)
                setUser(account)
            },
            onError: () => {
                loadingData.current.loading = false
                setIsError(true)
                setIsLoading(false)
            }
        }))
    }

    return (
        <UserContext.Provider value={{
            user: user ?? null,
            isLoading: isLoading,
            isError: isError,
            refresh: refresh,
            hasPermissions: hasPermissions
        }}>
            {children}
        </UserContext.Provider>
    )
}


export interface UseUserOptions {
    permissions?: string | string[]
    requireUser?: boolean
}

export function useUser(options: UseUserOptions = {}): UserContextProps {
    const context = useContext(UserContext)
    const requireUser = options.requireUser ?? true

    useEffect(() => {
        const currentUser = context.user
        if (!context.isLoading && !context.isError && !currentUser) {
            context.refresh()
        } else if (requireUser && context.isError) {
            window.location.pathname = "/"
            return
        }
        if (options.permissions && currentUser) {
            if (!userHasPermissions(currentUser, options.permissions)) {
                window.location.pathname = "/"
                return
            }
        }
    }, [context, options, requireUser])

    return {
        user: context.user,
        isLoading: context.isLoading,
        isError: context.isError,
        refresh: context.refresh,
        hasPermissions: context.hasPermissions
    }
}
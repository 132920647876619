import {PageTemplate} from "./PageTemplate";
import React, {useEffect, useState} from "react";
import {
    Box,
    Divider,
    Dropdown,
    IconButton,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Sheet,
    Snackbar,
    Stack,
    Table,
    Typography
} from "@mui/joy";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {TransactionResponse} from "../data/TransactionResponse";
import {formatMoney, formatTimestamp} from "../utils";
import {Pagination} from "../components/Pagination";
import {StatusIndicator} from "../components/StatusIndicator";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {ActiveSubscriptionComponent} from "../components/SubscriptionCard";
import {Close, FileCopy, MoreVert} from "@mui/icons-material";


interface TransactionRowProps {
    transaction: TransactionResponse
}

interface ShowInvoiceResponse {
    invoiceUrl: string | null
}

const TransactionRow = (props: TransactionRowProps) => {
    const transaction = props.transaction
    const items = transaction.items.map(item => item.productName).join(", ")
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [isLoading, setIsLoading] = useState(false)
    const [showInvoiceError, setShowInvoiceError] = useState(false)

    const showInvoice = () => {
        setApiCall(makeApiCall<ShowInvoiceResponse>({
            method: "GET",
            url: `/api/user/-/transaction/${props.transaction.transactionId}/invoice`,
            onLoadedCallback: (response) => {
                setIsLoading(false)
                if (response.invoiceUrl) {
                    window.open(response.invoiceUrl, "_blank")?.focus()
                } else {
                    setShowInvoiceError(true)
                }
            },
            onError: () => {
                setIsLoading(false)
                setShowInvoiceError(true)
            }
        }))
    }

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    return <tr>
        <td>{formatTimestamp(transaction.createdAt)}</td>
        <td><StatusIndicator status={transaction.status}/></td>
        <td>{items}</td>
        <td>{formatMoney(parseFloat(transaction.grandTotal), transaction.currencyCode)}</td>
        <td>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={showInvoiceError}
                autoHideDuration={10000}
                onClose={() => setShowInvoiceError(false)}
                color="danger"
                endDecorator={
                    <IconButton
                        onClick={() => setShowInvoiceError(false)}
                        size="sm"
                        color="danger"
                        variant="soft"
                    >
                        <Close/>
                    </IconButton>
                }
                size="md"
                variant="soft"
            >
                <Typography>
                    Could not find an invoice for this transaction.
                </Typography>
            </Snackbar>
            <LoadingSpinner loading={isLoading}/>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>
                    {formatTimestamp(transaction.updatedAt)}
                </Typography>
                <Dropdown>
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { color: 'neutral' } }}
                    >
                        <MoreVert />
                    </MenuButton>
                    <Menu>
                        <MenuItem disabled={isLoading} onClick={() =>
                            showInvoice()
                        }>
                            <ListItemDecorator>
                                <FileCopy />
                            </ListItemDecorator>{' '}
                            View Invoice
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Stack>
        </td>
    </tr>
}

interface GetTransactionsResponse {
    transactions: TransactionResponse[],
    totalPages: number
}


const TransactionsComponent = () => {
    const [page, setPage] = useState(1)

    const response = useApiCall<GetTransactionsResponse>({
        url: `/api/user/-/transactions?page=${page}`,
        redirectOnUnauthorized: true
    })
    return <Stack>
        <LoadingSpinner loading={response.isLoading}/>
        <Typography level="h3">
            Your Transactions
        </Typography>
        <Divider sx={{mt: 1, mb: 2}}/>
        <Table variant="outlined" sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
        }}>
            <thead>
            <tr>
                <th style={{padding: '12px 6px'}}>Date</th>
                <th style={{padding: '12px 6px'}}>Status</th>
                <th style={{padding: '12px 6px'}}>Items</th>
                <th style={{padding: '12px 6px'}}>Price</th>
                <th style={{padding: '12px 6px'}}>Last Updated</th>
            </tr>
            </thead>
            <tbody>
            {response.data?.transactions?.map((transaction) => {
                return <TransactionRow key={transaction.transactionId} transaction={transaction}/>
            })}
            </tbody>
        </Table>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            width: "100%"
        }}>
            <Pagination onPageSelected={setPage} page={page} totalPages={response.data?.totalPages ?? 1}/>
        </Box>
    </Stack>
}


export const TransactionPage = () => {

    return <PageTemplate>
        <Sheet sx={{p: 4, mt: 4}}>
            <Stack spacing={4}>
                <ActiveSubscriptionComponent/>
                <TransactionsComponent/>
            </Stack>
        </Sheet>
    </PageTemplate>

}
import {PropsWithChildren, useEffect, useState} from "react";
import {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    FormLabel,
    List,
    ListItem,
    ListItemDecorator,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {Check} from "@mui/icons-material";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {CardPropsColorOverrides} from "@mui/joy/Card/CardProps";
import {DetailDialog} from "./DetailDialog";
import Option from '@mui/joy/Option';
import {StripePriceInfoResponse} from "../data/StripeResponses";
import {formatMoney} from "../utils";
import {useStripe} from "@stripe/react-stripe-js";
import {useUser} from "../hooks/useUser";
import {TermsOfServiceModal} from "./TermsOfServiceModal";

interface StripeProductProps extends PropsWithChildren {
    name: string
    shortDescription: string
    buttonText: string
    priceInfo: StripePriceInfoResponse
    benefits: string[]
    canPurchase: boolean
    cardColor?: OverridableStringUnion<ColorPaletteProp, CardPropsColorOverrides>
    maxQuantity: number
    period?: string
}

interface StripeCreateCheckoutSessionResponse {
    sessionId: string
}

export const StripeProduct = (props: StripeProductProps) => {
    const stripe = useStripe()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [loading, setLoading] = useState(false)
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const user = useUser({requireUser: false})
    const [quantity, setQuantity] = useState(1)
    const [showTermsModal, setShowTermsModal] = useState(false)

    const quantitySelector = (props.maxQuantity === 1) ? <></> :
        <Box sx={{position: "absolute", bottom: "3%", left: "5%"}}>
            <FormControl sx={{width: 240}}>
                <FormLabel id="select-field-demo-label" htmlFor="select-field-demo-button">
                    Quantity
                </FormLabel>
            </FormControl>
            <Select sx={{width: 80}} value={quantity} onChange={(_, newValue) => setQuantity(newValue ?? 1)}>
                {(Array.from({length: props.maxQuantity}, (_, i) => {
                    return <Option value={i + 1}>{i + 1}</Option>
                }))}
            </Select>
        </Box>

    const startCheckoutSession = () => {
        if (!user.user) {
            window.location.href = "/api/auth/steam"
            return
        } else if (!user?.user?.termsOfServiceAccepted) {
            setShowTermsModal(true)
            return
        } else if (!props.canPurchase) {
            window.location.href = "/api/stripe/customer-portal-session"
            return
        }
        setLoading(true)
        setApiCall(makeApiCall<StripeCreateCheckoutSessionResponse>({
            url: `/api/stripe/create-checkout-session?lookup_key=${props.priceInfo.lookupKey}&quantity=${quantity}`,
            method: "POST",
            onLoadedCallback: (data) => {
                stripe?.redirectToCheckout({
                    sessionId: data.sessionId
                })
                setLoading(false)
            },
            onError: () => {
                setLoading(false)
            }
        }))
    }

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    return <Box>
        <TermsOfServiceModal visible={showTermsModal} onTermsAccepted={() => {
            setShowTermsModal(false)
            setLoading(true)
            makeApiCall({
                method: "POST",
                url: "/api/user/-/terms-of-service/accept",
                onError: () => {
                    setLoading(false)
                },
                onLoadedCallback: (_) => {
                    setLoading(false)
                    user.refresh()
                }
            })
        }} onCancelled={() => setShowTermsModal(false)}
        />
        <Card sx={(theme) => ({p: 4, [theme.breakpoints.up("sm")]: {minWidth: 500, maxWidth: 500}, height: "100%"})}
              color={props.cardColor}>
            <CardContent orientation="vertical">
                <Stack direction="column" spacing={3} sx={{paddingBottom: "35px"}}>
                    <Typography level="h3">
                        {props.name}
                    </Typography>
                    <Divider inset="none"/>
                    <Box sx={{minHeight: "15%", maxHeight: "15%"}}>
                        <Typography>
                            {props.shortDescription}
                        </Typography>
                    </Box>
                    <Typography level="h2">
                        {formatMoney(props.priceInfo.amount, props.priceInfo.currency)}
                        {
                            (props.period) ? <Typography level="body-md" sx={{fontWeight: 'lg', ml: 1}} color="neutral">
                                / {props.period}
                            </Typography> : <></>
                        }
                    </Typography>
                    <Button
                        variant={props.canPurchase ? "solid" : "outlined"}
                        size="md"
                        color="primary"
                        sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600, minWidth: '100%'}}
                        onClick={startCheckoutSession}
                        disabled={loading || user.isLoading}
                    >
                        {props.buttonText}
                    </Button>
                    <Stack sx={{paddingBottom: "5%"}} direction={"column"}>
                        <Typography
                            level="body-xs"
                            sx={{textTransform: 'uppercase', fontWeight: 'lg', mb: 1}}
                        >
                            Benefits
                        </Typography>
                        <List>
                            {props.benefits.map((item, index) => (
                                <ListItem key={index}><ListItemDecorator><Check
                                    color="primary"/></ListItemDecorator>{item}
                                </ListItem>
                            ))}
                        </List>
                        {quantitySelector}
                        <Button sx={{position: "absolute", bottom: "3%", right: "5%"}} variant="outlined" size="md"
                                onClick={() => setShowMoreInfo(true)}>More info {">"}</Button>
                        <DetailDialog open={showMoreInfo} handleClose={() => {
                            setShowMoreInfo(false)
                        }}/>
                        {props.children}
                    </Stack>
                    {props.children}
                </Stack>
            </CardContent>
        </Card>
    </Box>
}
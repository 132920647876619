import {Box, Container, CssBaseline} from "@mui/joy";
import {NavBar} from "../components/NavBar";
import {PropsWithChildren} from "react";
import {Footer} from "../components/Footer";

interface PageTemplateProps extends PropsWithChildren {

}

export const PageTemplate = (props: PageTemplateProps) => {
    return <div style={{height: "100%"}}>
        <CssBaseline/>
        <Box sx={{minHeight: "100%", display: "flex", flexDirection: "column", flexGrow: 1}}>
            <NavBar/>
            <Container maxWidth="lg" style={{overflow: "auto"}}>
                {props.children}
            </Container>
            <Footer/>
        </Box>
    </div>
}
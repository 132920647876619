import {DialogTitle, List, ListItem, Modal, ModalClose, ModalDialog} from "@mui/joy";
import {DetailCard} from "./DetailCard";

interface DetailDialogProps  {
    open: boolean
    handleClose: () => void
}

export const DetailDialog = (props: DetailDialogProps) => {
    return <Modal
        aria-labelledby="close-modal-title"
        open={props.open}
        onClose={props.handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ModalDialog sx={{overflow: 'auto', width: {xs: "90%", sm: "80%", md: "80%", lg: "auto"} }}>
            <ModalClose/>
            <DialogTitle level="h3">Premium features</DialogTitle>
            <DetailCard title="Exclusive vehicles" description="" image="/images/2fast.jpg">
                <List marker="disc">
                    <ListItem>Access to purchase exclusive premium vehicles</ListItem>
                    <ListItem>After market <strong>body kits</strong></ListItem>
                    <ListItem>Customizable speedometer</ListItem>
                </List>
            </DetailCard>
            <DetailCard title="Clothing accessories" description="" image="/images/night.jpg">
            <List marker="disc">
                <ListItem>Access to <strong>accessories</strong> - Exclusive hats, shades, glasses and more!</ListItem>
                <ListItem>Custom <strong>decals</strong> for clothing</ListItem>
            </List>
            </DetailCard>
            <DetailCard title="Additional benefits" description="" image="/images/physgun.jpg">
                <List marker="disc">
                    <ListItem><strong>Earn more money</strong> - get 2 times as much as an unemployed citizen and 1.75 times more as a government employee.</ListItem>
                    <ListItem>Increased prop limit to 40</ListItem>
                    <ListItem>Carry a default weight of 250 instead of only 150.</ListItem>
                    <ListItem>Extra <strong>planters</strong> (up to 2 planters instead of 1).</ListItem>
                    <ListItem>Gain an extra <strong>daily spin</strong></ListItem>
                    <ListItem>Earn 20% more job <strong>experience</strong></ListItem>
                    <ListItem>Always get a <strong>government car</strong></ListItem>
                    <ListItem>Access to all <strong>phone apps</strong></ListItem>
                </List>
            </DetailCard>
        </ModalDialog>
    </Modal>
}
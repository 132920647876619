import {Box, CircularProgress} from "@mui/joy";

interface LoadingSpinnerProps {
    loading: boolean
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
    return <Box sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: props.loading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10000,
        backgroundColor: "rgba(0,0,0,0.5)"
    }}>
        <CircularProgress/>
    </Box>
}
import {Box, IconButton, iconButtonClasses} from "@mui/joy";
import React from "react";

interface PaginationButtonProps {
    page: string
    isSelected: boolean
    onPageSelected: (page: number) => void
}

export const PaginationButton = (props: PaginationButtonProps) => {
    const number = Number(props.page)
    return <IconButton
        size="sm"
        variant={number ? 'outlined' : 'plain'}
        disabled={number === undefined}
        color={props.isSelected ? "primary" : "neutral"}
        onClick={() => {
            if (number) {
                props.onPageSelected(number)
            }
        }}
    >
        {props.page}
    </IconButton>
}

interface PaginationProps {
    page: number
    totalPages: number
    onPageSelected: (page: number) => void
}

function getButtonLabels(page: number, totalPages: number): string[] {
    if (totalPages <= 1) return ["1"]
    if (totalPages <= 7) return [1, 2, 3, 4, 5, 6, 7].filter(a => a <= totalPages).map(a => a.toFixed())

    const startPages = ["1"]
    const middleLabels = ["..."]
    const endPages = [totalPages.toFixed()]

    if (page <= 4) {
        startPages.push("2", "3", "4", "5")
    }
    else if (page >= totalPages - 3) {
        endPages.unshift((totalPages - 4).toFixed(), (totalPages - 3).toFixed(), (totalPages - 2).toFixed(), (totalPages - 1).toFixed())
    }

    if (page > 4 && page < totalPages - 3) {
        middleLabels.push((page - 1).toFixed())
        middleLabels.push(page.toFixed())
        middleLabels.push((page + 1).toFixed())
        middleLabels.push("...")
    }

    return startPages.concat(middleLabels).concat(endPages)
}

export const Pagination = (props: PaginationProps) => {
    const buttonLabels = getButtonLabels(props.page, props.totalPages)

    return <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            width: "100%",
            [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
            p: 2
        }}
    >
        {buttonLabels.map((label, index) => <PaginationButton key={index}
                                                       isSelected={Number(label) === props.page}
                                                       onPageSelected={props.onPageSelected}
                                                       page={label}/>)}
    </Box>
}
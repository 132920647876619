import React, {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import {StorePage} from "./pages/StorePage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {UserProvider} from "./hooks/useUser";
import {CssVarsProvider, extendTheme} from '@mui/joy/styles';
import {StyledEngineProvider} from "@mui/joy";
import {TransactionPage} from "./pages/TransactionPage";
import {TermsOfServicePage} from "./pages/TermsOfServicePage";
import {PrivacyPolicyPage} from "./pages/PrivacyPolicyPage";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {CheckoutSuccessPage} from "./pages/CheckoutSuccessPage";
import {NotFoundPage} from "./pages/NotFoundPage";

const root = createRoot(
    document.getElementById('root') as HTMLElement
)

const theme = extendTheme({})

const router = createBrowserRouter([
    {
        path: "/",
        element: <StorePage/>
    },
    {
        path: "/transactions",
        element: <TransactionPage/>
    },
    {
        path: "/terms-of-service",
        element: <TermsOfServicePage/>
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage/>
    },
    {
        path: "/checkout/success",
        element: <CheckoutSuccessPage/>
    },
    {
        path: "*",
        element: <NotFoundPage/>
    },
]);

interface StripeResponse {
    publishableKey: string
}

async function initializeStripe() {
    const response = await (fetch("/api/stripe"))
    const stripeResponse = await response.json() as StripeResponse
    return await loadStripe(stripeResponse.publishableKey)
}

const App = () => {
    return <StrictMode>
        <StyledEngineProvider injectFirst>
            <CssVarsProvider
                theme={theme}
                defaultMode="system"
                modeStorageKey="theme-mode"
            >
                <Elements stripe={initializeStripe()}>
                    <UserProvider>
                        <RouterProvider router={router}/>
                    </UserProvider>
                </Elements>
            </CssVarsProvider>
        </StyledEngineProvider>
    </StrictMode>
}

root.render(<App/>);
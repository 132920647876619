import {PageTemplate} from "./PageTemplate";
import {Button, Sheet, Stack, Typography} from "@mui/joy";
import {Home} from "@mui/icons-material";
import {Link} from "react-router-dom";

export const NotFoundPage = () => {
    return <PageTemplate>
        <Sheet sx={{p: 4, mt: 4, borderRadius: 6}}>
            <Stack spacing={3} sx={{alignItems: "center"}}>
                <Typography level="h1" sx={{fontSize: 200}}>:(</Typography>
                <Typography level="h3">Page Not Found</Typography>
                <Button startDecorator={<Home sx={{ position: "relative", top: "2px" }} />} component={Link} to="/" sx={{fontSize: 20, mt: "5% !important"}} variant="outlined">Back To Home</Button>
            </Stack>
        </Sheet>
    </PageTemplate>
}
export function formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp)
    return date.toLocaleDateString(window.navigator.language, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    })
}

export function formatLongDate(timestamp: string): string {
    const date = new Date(timestamp)
    return date.toLocaleDateString(window.navigator.language, {
        month: "long",
        day: "2-digit",
        year: "numeric",
    })
}

export function formatMoney(amount: number, currencyCode: string): string {
    return Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currencyCode,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(amount / 100)
}
import {Chip, Stack, Typography} from "@mui/joy";
import React from "react";
import {
    Autorenew,
    Block,
    CheckRounded,
    HourglassBottom,
    HourglassEmpty,
    Pause,
    QuestionMark
} from "@mui/icons-material";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {ChipPropsColorOverrides} from "@mui/joy/Chip/ChipProps";

interface StatusIndicatorProps {
    status: string
}

interface StatusEntry {
    name: string,
    color: OverridableStringUnion<ColorPaletteProp, ChipPropsColorOverrides>,
    icon: React.JSX.Element
}

const statusEntries = new Map<string, StatusEntry>([
    ["active", {
        name: "Active",
        color: "success",
        icon: <CheckRounded/>
    }],
    ["inactive", {
        name: "Inactive",
        color: "neutral",
        icon: <Pause/>
    }],
    ["paused", {
        name: "Paused",
        color: "neutral",
        icon: <Pause/>
    }],
    ["draft", {
        name: "Draft",
        color: "neutral",
        icon: <HourglassEmpty/>
    }],
    ["ready", {
        name: "Pending",
        color: "warning",
        icon: <HourglassBottom/>
    }],
    ["billed", {
        name: "Pending",
        color: "warning",
        icon: <HourglassBottom/>
    }],
    ["paid", {
        name: "Payment Received",
        color: "success",
        icon: <Autorenew/>
    }],
    ["completed", {
        name: "Completed",
        color: "success",
        icon: <CheckRounded/>
    }],
    ["cancelling", {
        name: "Active (Cancelling)",
        color: "success",
        icon: <Block/>
    }],
    ["canceled", {
        name: "Cancelled",
        color: "danger",
        icon: <Block/>
    }],
    ["past_due", {
        name: "Past Due",
        color: "danger",
        icon: <Block/>
    }]
])

export const StatusIndicator = (props: StatusIndicatorProps) => {
    const statusEntry = statusEntries.get(props.status)

    return <Stack direction="row" alignItems="center">
        <Chip
            variant="soft"
            size="sm"
            startDecorator={statusEntry?.icon ?? <QuestionMark/>}
            color={statusEntry?.color ?? "neutral"}
        >
            <Typography sx={{paddingBottom: "1px"}}>{statusEntry?.name ?? "Unknown"}</Typography>
        </Chip>
    </Stack>
}
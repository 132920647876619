import {Box, Container, CssBaseline} from "@mui/joy";
import {NavBar} from "../components/NavBar";
import {Footer} from "../components/Footer";
import {LegalMarkdown} from "../components/LegalMarkdown";

export const PrivacyPolicyPage = () => {

    return <div style={{height: "100%"}}>
        <CssBaseline/>
        <Box sx={{minHeight: "100%", display: "flex", flexDirection: "column", flexGrow: 1}}>
            <NavBar/>
            <Container maxWidth="lg" style={{overflow: "auto"}}>
                <LegalMarkdown path="/legal/privacy_notice.md"></LegalMarkdown>
            </Container>
            <Footer/>
        </Box>
    </div>
}
import {
    Button,
    Card,
    CardContent,
    List,
    ListItem,
    Skeleton,
    Stack,
    Typography,
} from "@mui/joy";

export const PremiumSubscriptionSkeleton = () => {

    return <Card sx={(theme) => ({ p: 4, [theme.breakpoints.up("sm")]: { minWidth: 500 }})}>
            <CardContent orientation="vertical">
                <Stack direction="column" spacing={3}>
                    <Typography>
                        <Skeleton>
                            Premium Subscription
                        </Skeleton>
                    </Typography>
                    <Typography>
                        <Skeleton>
                            Short description regarding this tier
                        </Skeleton>
                    </Typography>
                    <Stack direction="row" spacing={0.5} alignItems="flex-end">
                            <Typography level="h2">
                                <Skeleton>
                                    6.99/30 days
                                </Skeleton>

                            </Typography>
                    </Stack>
                    <Button
                        variant="solid"
                        size="md"
                        color="primary"
                        aria-label="Buy Premium"
                        sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600, minWidth: '100%'}}
                    >
                        <Skeleton>
                            Subscribe
                        </Skeleton>
                    </Button>
                    <Stack direction={"column"}>
                        <Typography
                            level="body-xs"
                            sx={{textTransform: 'uppercase', fontWeight: 'lg', mb: 1}}
                        >
                            Benefits
                        </Typography>
                        <List >
                            <ListItem sx={{ marginY: "1%" }}>
                                <Skeleton width="55%" >
                                    More money
                                </Skeleton>
                            </ListItem>
                            <ListItem sx={{ marginY: "1%" }}>
                                <Skeleton width="50%" >
                                    More money
                                </Skeleton>
                            </ListItem>
                            <ListItem sx={{ marginY: "1%" }}>
                                <Skeleton width="55%" >
                                    More money
                                </Skeleton>
                            </ListItem>
                            <ListItem sx={{ marginY: "1%" }}>
                                <Skeleton width="50%" >
                                    More money
                                </Skeleton>
                            </ListItem>
                        </List>
                    </Stack>
                        <a style={{fontSize: "10px", color: "inherit", margin: "auto"}} href="/transactions">
                        </a>
                </Stack>
            </CardContent>
        </Card>
}
import {Stack} from "@mui/joy";
import React from "react";
import {PageTemplate} from "./PageTemplate";
import {PremiumSubscription} from "../components/PremiumSubscription";
import useApiCall from "../hooks/CancellableApiCall";
import {PremiumToken} from "../components/PremiumToken";
import {AllStripePriceInfoResponse} from "../data/StripeResponses";
import {PremiumSubscriptionSkeleton} from "../components/Skeletons/PremiumSubscriptionSkeleton";

export const StorePage = () => {
    const productsResponse = useApiCall<AllStripePriceInfoResponse>({
        url: "/api/stripe/products",
    })

    const premiumSubscription = productsResponse?.data?.prices.find((a) => a.lookupKey === "premium_monthly_subscription")
    const premiumToken = productsResponse.data?.prices?.find((a) => a.lookupKey === "premium_token")
    if (!productsResponse.data || !premiumToken || !premiumSubscription) {
        return <PageTemplate>
            <Stack spacing={{xs: 4, lg: 4}} direction={{md: "column", lg: "row"}} sx={(theme) => ({ p: 4, [theme.breakpoints.down("lg")]: { alignItems: "center" }, justifyContent: "center",  alignItems: "stretch"})}>
                <PremiumSubscriptionSkeleton></PremiumSubscriptionSkeleton>
                <PremiumSubscriptionSkeleton></PremiumSubscriptionSkeleton>
            </Stack>
        </PageTemplate>
    }
    return <PageTemplate>
        <Stack spacing={{xs: 4, lg: 4}} direction={{md: "column", lg: "row"}} sx={(theme) => ({ p: 4, [theme.breakpoints.down("lg")]: { alignItems: "center" }, justifyContent: "center",  alignItems: "stretch"})}>
            <PremiumSubscription name="Premium"
                                 shortDescription="Upgrade to premium for enhanced gameplay, exclusive perks, and more ways to elevate your experience."
                                 priceInfo={premiumSubscription}
                                 benefits={["Exclusive cars", "Exclusive clothing", "More planters", "Higher paychecks"]}/>
            <PremiumToken name="Premium Token"
                          shortDescription="Redeem to gain 30 day access to all premium benefits with a single, non-recurring payment. Delivered as an in-game token, it can be activated anytime or gifted to another player."
                          priceInfo={premiumToken}
                          benefits={[ "All premium benefits", "Can be gifted or traded", "One time payment"]}/>
        </Stack>
    </PageTemplate>
}
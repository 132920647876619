import {PageTemplate} from "./PageTemplate";
import {Button, Sheet, Stack, Typography} from "@mui/joy";
import {Check, Home} from "@mui/icons-material";
import { Link } from 'react-router-dom';
import ConfettiExplosion from "react-confetti-explosion";
import {useState} from "react";

export const CheckoutSuccessPage = () => {
    const [isExploding, setIsExploding] = useState(false);

    setTimeout(() => setIsExploding(true), 300);
    return <PageTemplate>
        <Sheet sx={{p: 4, mt: 4, borderRadius: 6}}>
            <Stack spacing={3} sx={{alignItems: "center"}}>
                {isExploding && <ConfettiExplosion  force={0.8} particleCount={250} width={1600}/>}
                <Check sx={{fontSize: 200}} color="success"/>
                <Typography level="h2">Your purchase was successful!</Typography>
                <Typography level="h3">Thank you! Your support means a lot to us.</Typography>
                <Typography>The purchase will be automatically fulfilled in-game.</Typography>
                <Button startDecorator={<Home sx={{ position: "relative", top: "2px" }} />} component={Link} to="/" sx={{fontSize: 20, mt: "5% !important"}} variant="outlined">Back To Home</Button>
            </Stack>
        </Sheet>
    </PageTemplate>
}
import {Avatar, Box, Dropdown, Menu, MenuButton, MenuItem, Typography,} from "@mui/joy";
import {FormatListBulleted, Logout} from "@mui/icons-material";
import {AccountResponse} from "../hooks/useUser";

interface UserButtonProps {
    user: AccountResponse;
    isLoading: boolean;
}

const logout = () => {
    // hmm
    window.location.href = '/api/auth/logout'
    return
};

export const UserButton = (props: UserButtonProps) => {
    return <Box>
        <Dropdown>
            <MenuButton variant="plain">
                <Avatar alt="Steam avatar" size="lg" src={props.user?.mediumAvatarLink ? props.user?.mediumAvatarLink : "?"}/> <Typography sx={{paddingLeft: '10px'}}>{props.user.nick}</Typography>
            </MenuButton>
            <Menu>
                <MenuItem onClick={(e) => {
                    window.location.href = "/api/stripe/customer-portal-session"
                }}>
                    <FormatListBulleted sx={{paddingTop: '4px'}} />
                    Manage Billing
                </MenuItem>
                <MenuItem onClick={logout} >
                    <Logout sx={{paddingTop: '4px'}} />
                    Logout
                </MenuItem>
            </Menu>
        </Dropdown>
    </Box>
}
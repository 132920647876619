import {
    Button,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography
} from "@mui/joy";
import React, {useEffect, useState} from "react";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {useNavigate} from "react-router-dom";
import {LoadingSpinner} from "./LoadingSpinner";
import {StatusIndicator} from "./StatusIndicator";
import {formatLongDate} from "../utils";
import {SubscriptionResponse} from "../data/SubscriptionResponse";

interface ActiveSubscriptionCardProps {
    subscription: SubscriptionResponse
}

const ActiveSubscriptionCard = ({subscription}: ActiveSubscriptionCardProps) => {
    const productString = subscription.items.map(s => s.productName).join(", ")
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const scheduledToCancel = subscription.scheduledChangeAction === "cancel"
    let status = (scheduledToCancel) ? "cancelling" : subscription.status

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    const cancelSubscription = () => {
        setApiCall(makeApiCall({
            method: "POST",
            url: "/api/user/-/subscriptions/cancel",
            onLoadedCallback: () => {
                setIsLoading(false)
                navigate(0)
            },
            onError: () => {
                setIsLoading(false)
            }
        }))
    }

    const uncancelSubscription = () => {
        setApiCall(makeApiCall({
            method: "POST",
            url: "/api/user/-/subscriptions/uncancel",
            onLoadedCallback: () => {
                setIsLoading(false)
                navigate(0)
            },
            onError: () => {
                setIsLoading(false)
            }
        }))
    }

    return <Card>
        <LoadingSpinner loading={isLoading}/>
        <Stack direction="row" spacing={2}>
            <Typography level="h3">{productString} Subscription</Typography>
            <StatusIndicator status={status}/>
        </Stack>
        <CardContent>
            <Stack spacing={1}>
                {subscription.activeUntil ?
                    <Typography>Your subscription {status === 'active' ? 'renews' : 'ends'} on {formatLongDate(subscription.activeUntil)}</Typography> : <></>}

                    <Button size="md" variant="outlined" sx={{width: "25%", marginLeft: "auto !important"}} color={(scheduledToCancel) ? "success" : "neutral"} onClick={() => {
                    if (scheduledToCancel) {
                        uncancelSubscription()
                    }
                    else {
                        cancelSubscription()
                    }
                }}>
                    {(scheduledToCancel) ? "Stop Cancellation" : "Cancel Subscription"}
                </Button>
            </Stack>
        </CardContent>
    </Card>
}

export const ActiveSubscriptionComponent = () => {
    const response = useApiCall<SubscriptionResponse>({
        url: `/api/user/-/subscriptions/active`,
        redirectOnUnauthorized: true
    })

    if (!response.data) {
        return <></>
    } else {
        return <Stack>
            <LoadingSpinner loading={response.isLoading}/>
            <Typography level="h3">
                Your Active Subscription
            </Typography>
            <Divider sx={{mt: 1, mb: 2}}/>
            <ActiveSubscriptionCard subscription={response.data}/>
        </Stack>
    }
}
import {Box, Button, Container, Stack, Theme, useColorScheme} from "@mui/joy";
import {ModeToggle} from "./ModeToggle";
import {Login} from "@mui/icons-material";
import {useUser} from "../hooks/useUser";
import {UserButton} from "./UserButton";
import {useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";

export const NavBar = () => {
    const userData = useUser({requireUser: false})
    const user = userData.user
    const {mode} = useColorScheme();
    const isMobileScreen = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down('md')
    )

    const isDarkMode = mode === "dark" || (mode === "system" && window.matchMedia('(prefers-color-scheme: dark)').matches)
    let logo
    if (isMobileScreen) {
        logo = "/logo192.png"
    } else {
        logo = isDarkMode ? "/logo-wide-dark.png" : "/logo-wide-light.png"
    }

    let loginButton
    if (user) {
        loginButton = <UserButton user={user} isLoading={userData.isLoading}/>
    } else {
        loginButton =
            <Button startDecorator={<Login/>} color="success" component="a" variant="solid"
                    href="/api/auth/steam" disabled={userData.isLoading}>
                Login
            </Button>
    }
    return <Box
        component="nav"
        sx={{
            backgroundColor: 'background.surface',
            height: '80px',
        }}>
        <Container maxWidth="lg" sx={{
            display: 'flex',
            justifySelf: 'stretch',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        }}>
            <Stack direction="row" padding={2} spacing={2} sx={{
                alignItems: 'center',
                flexGrow: 1,
                justifyItems: 'flex-start',
            }}>
                <Link to="/" style={{height: '65px'}}>
                    <img
                         style={{height: '100%'}}
                         alt="Perpheads logo"
                         src={logo}></img>
                </Link>
            </Stack>
            <Stack direction="row" padding={2} spacing={4} sx={{
                alignItems: 'center',
                flexGrow: 1,
                justifySelf: 'end',
                justifyContent: 'flex-end',
                marginRight: 'auto'
            }}>
                <ModeToggle/>
                <Box>
                    {loginButton}
                </Box>
            </Stack>
        </Container>
    </Box>
}
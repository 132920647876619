import {Button, Modal, ModalClose, Sheet, Stack, Typography} from "@mui/joy";

interface TermsOfServiceModalProps {
    visible: boolean
    onTermsAccepted: () => void
    onCancelled: () => void
}

export const TermsOfServiceModal = (props: TermsOfServiceModalProps) => {
    return <Modal
        open={props.visible}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Sheet
            variant="outlined"
            sx={{maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg'}}
        >
            <ModalClose variant="plain" sx={{m: 1}} onClick={props.onCancelled}/>
            <Typography
                component="h2"
                level="h4"
                textColor="inherit"
                sx={{fontWeight: 'lg', mb: 1}}
            >
                Terms and conditions
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
                You need to accept our <a href="/terms-of-service" target="_blank">terms of service</a> before you can purchase this item.
            </Typography>
            <Stack sx={{mt: 2, width: "100%", mr: 2, justifyContent: "flex-end"}} spacing={1} direction="row">
                <Button color="success" onClick={props.onTermsAccepted}>
                    Accept
                </Button>
                <Button color="neutral" variant="outlined" onClick={props.onCancelled}>
                    Decline
                </Button>
            </Stack>
        </Sheet>
    </Modal>
}